import React from "react"
import Head from "../components/head.js"
import { graphql } from "gatsby"
import Navigation from "../components/navigation.js"

export default function About({data}) {
  return (
    <>
      <Head />

      <Navigation />
        
        <main className="about">
            <section >
                <div>
                    <p className="quote">"Was the Earth made to preserve a few covetous, proud men to live at ease, and for them to wall off the treasures of the Earth from others, that these may beg or starve in a fruitful land? Or was it made to preserve all her children?"</p>
                </div>
            </section>
        </main>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`