import React from "react"
import { Link } from "gatsby"

import logo from "../images/LogoColor.svg"

export default function Navigation () {

  return (
    <>
      <header>
        <Link to="/">
        <div className="nav-title">
          <img src={logo} alt="" />
          <h1>LEVELLER</h1>
        </div>
        </Link>
        { /*
        <a href="#main-menu"
           id="main-menu-toggle"
           className="menu-toggle"
           aria-label="Open main menu">
          <span className="sr-only">Open main menu</span>
          <span aria-hidden="true">&#8226; &#8226; &#8226;</span>
        </a>
          
        <nav id="main-menu" className="main-menu" aria-label="Main menu">
          <a href="#main-menu-toggle"
             id="main-menu-close"
             className="menu-close"
             aria-label="Close main menu">
            <span className="sr-only">Close main menu</span>
            <span aria-hidden="true">&#10006;</span>
          </a>
          
          <ul>
            <li><Link to="/">home</Link></li>
            <li><Link to="/about/">about</Link></li>
            <li><Link to="/tools/">tools</Link></li>
          </ul>
          
        </nav>
        <a href="#main-menu-toggle"
           className="backdrop"
           tabindex="-1"
           aria-hidden="true" hidden></a>
           */ }
      </header>
    </>
  )
}